<template>
  <v-card flat :style="`height: ${ windowSize.y + 70 }px; position: 'relative'`" class="pa-1 product-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0">
      <v-form ref="pickForm" v-on:submit.prevent>
        <v-row>
          <v-col cols="12" class="pa-0 pl-3 font-weight-bold">
            {{ $t('message.ccsheet.warehouse') }}: {{ sheetModel.warehousename }}
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="7" class="pt-0 pr-0 pb-0 mt-1">
            <barcode-scanner v-model="scannedLocation" :search="scannedLocation" :scannerPayload="locationScannerPayload"
            :loading="loadingLocation" :disabled="lockLocation"></barcode-scanner>
            <!-- <v-text-field outlined dense hide-details v-model="scannedLocation" flat autocomplete="off" :disabled="lockLocation"
              ref="scanLocation" @change="checkLocationAlreadyCounted" :loading="loadingLocation"
            :placeholder="$t('message.ccsheet.scanLocation')"></v-text-field> -->
          </v-col>
          <v-col cols="5" class="pa-0 pr-3 mt-2 text-center">
            <v-btn small color="primary" tabindex="-1" @click="scanNextLocation">{{ $t('message.ccsheet.nextLoc') }}</v-btn>
          </v-col>
          <v-col cols="12" class="pt-0 pb-0 mt-1">
            <barcode-scanner v-model="scanProduct" :search="scanProduct" :scannerPayload="productScannerPayload"
            :loading="loadingProduct"></barcode-scanner>
            <!-- <v-text-field outlined dense hide-details v-model="scanProduct" flat autocomplete="off"
              ref="scanProduct" @change="getRespectiveProductOrder" :loading="loadingProduct"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
          <v-col cols="12" class="pt-1 pb-1 font-weight-bold" v-if="productFound">
            {{ productInfo.productname || '' }}
          </v-col>
          <template v-if="productFound && productInfo.producthasbatch">
            <v-col cols="8" class="pt-0 pr-0 pb-0">
              <v-text-field outlined dense hide-details v-model="selectBatch" flat autocomplete="off"
              @change="checkForValidBatchNumber" :loading="loadingBatch"
              :rules="$_requiredValidation" :placeholder="$t('message.product.batch')" ref="batchRef"></v-text-field>
            </v-col>
          </template>
        </v-row>
        <v-row v-if="productFound || this.showAddCount">
          <v-col cols="9" class="pa-1">
            <v-row>
              <v-col cols="3" class="text-center pa-0" @click="decreaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty"
                  autocomplete="off" ref="qtyRef" :rules="pickQty ? $_zeroValidation : []" :placeholder="$t('message.product.qty')"></v-text-field>
              </v-col>
              <v-col cols="3" class="text-center pa-0" @click="increaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="pa-1 mt-1">
            <v-btn color="primary" dark small :loading="saveLoading" @click="saveCountedProduct">
              {{ $t('message.ccsheet.count') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row>
        <v-col cols="4" class="pa-0 pl-3 font-weight-bold">{{ $t('message.whsDashboard.product') }}</v-col>
        <v-col cols="3" class="pa-0 pl-3 text-center font-weight-bold">{{ $t('message.order.location') }}</v-col>
        <v-col cols="1" class="pa-0 text-right font-weight-bold">{{ $t('message.ccsheet.unit') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold">{{ $t('message.ccsheet.stock') }}</v-col>
        <v-col cols="2" class="pa-0 text-right font-weight-bold smaller-font">{{ $t('message.ccsheet.counted') }}</v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <template v-for="(product, index) in listOfProducts">
        <v-row :key="`${index}_stock`" class="ma-0">
          <v-col cols="4" class="pa-0 pl-3 text-truncate bigger-font" :title="product.productnumber" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
            {{ product.productnumber }}
          </v-col>
          <v-col cols="3" class="pa-0 text-center text-truncate" :title="product.locationname">{{ product.locationname }}</v-col>
          <v-col cols="1" class="pa-0 text-right text-truncate smaller-font" :title="product.unit">{{ product.unit }}</v-col>
          <v-col cols="2" class="pa-0 text-right text-truncate" :title="product.onstock">{{ product.onstock | absoluteNumber }}</v-col>
          <v-col cols="2" class="pa-0 pl-1 text-right text-truncate" :title="product.counted">
            {{ product.counted | absoluteNumber }}
          </v-col>
        </v-row>
        <v-row :key="`${index}_product`" class="ma-0">
          <v-col cols="10" class="pa-0 pl-4 text-truncate smaller-font">{{ product.productname }}</v-col>
          <v-col cols="2" class="pa-0 text-right text-truncate">{{ product.batchnumber }}</v-col>
        <v-col cols="12" class="pa-0 pt-1">
          <v-divider class="pa-0"></v-divider>
        </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-pagination v-model="activePage" :length="totalLength" circle :total-visible="5"></v-pagination>
    <v-fab-transition>
      <v-btn bottom left color="primary complete_count_btn" id="scroll__top" class="mt-2 complete_count_btn" tabindex="-1" fixed @click="completeCountingHandler" v-if="can_complete==1">
         {{ $t('message.ccsheet.closeComplete') }}
      </v-btn>
    </v-fab-transition>
    <!-- Show warning when the product is counted already in a location! -->
    <v-dialog v-model="showWarning" persistent class="mt-0">
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('message.ccsheet.info') }}
          <v-spacer></v-spacer>
          <v-btn color="error" icon id="warningClose" fab small @click="showWarning=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <h4>{{ $t('message.ccsheet.stockTake') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="info" small id="continueCounting" @click="continueCoutingHandler">{{ $t('message.ccsheet.continueCount') }}</v-btn>
          <v-btn color="success" small id="clearCounting" @click="clearAndRecountHandler">{{ $t('message.ccsheet.clearAndRecount') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="completeDialog" persistent class="mt-0">
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('message.ccsheet.info') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <h4>{{ $t('message.ccsheet.ccsheetComplete') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" small :loading="completeLoading" @click="completeSheetItems">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="success" small @click="completeDialog = false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addProductConfirmDialog" persistent class="mt-0">
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('message.ccsheet.info') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <h4>{{ $t('message.ccsheet.ccsheetAddProduct') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" small :loading="completeLoading" @click="proceedWithProduct">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="success" small @click="addProductConfirmDialog = false, foundProduct = {}">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfProducts: [],
      listOfLoadableProducts: [],
      loading: false,
      windowSize: {
        x: 0,
        y: 0
      },
      saveLoading: false,
      completeLoading: false,
      loadingBatch: false,
      sheetModel: {},
      scanProduct: '',
      scannedLocation: '',
      selectBatch: '',
      productInfo: {},
      productFound: false,
      pickQty: '',
      sheetId: 0,
      showWarning: false,
      selectedProduct: {},
      lockLocation: false,
      isContinueCounting: 0,
      isClearAndCount: 0,
      completeDialog: false,
      loadingLocation: false,
      loadingProduct: false,
      addProductConfirmDialog: false,
      foundProduct: {},
      showAddCount: false,
      productUnits: [],
      activePage: 1,
      totalLength: 0,
      can_complete: 0,
      /* Scanner field */
      locationScannerPayload: {
        placeholder: 'message.ccsheet.scanLocation',
        change: this.checkLocationAlreadyCounted,
        attrRef: 'scannerFieldFocus1',
        attrId: 'scannerField1'
      },
      productScannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus2',
        attrId: 'scannerField2'
      }
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.sheetId = this.$route.params.sheet_id
    // this.getProductUnits()
    this.getCountedProducts()
    this.getListOfProducts()
    this.focusField('scanLocation')
    /* set loading disabled */
    // this.locationScannerPayload.loading = this.loadingLocation
    // this.productScannerPayload.loading = this.loadingProduct
    // this.locationScannerPayload.disabled = this.lockLocation
  },
  watch: {
    activePage (val) {
      this.getListOfProducts()
    }
  },
  methods: {
    checkLocationAlreadyCounted () {
      const getProducts = this.sheetModel.stocks
      if (getProducts) {
        this.loadingLocation = true
        const checkLoc = getProducts.find(x => x.locationname === this.scannedLocation)
        if (checkLoc) {
          const getLoc = getProducts.filter(x => x.locationname === this.scannedLocation)
          if (getLoc && getLoc.length > 0) {
            this.showWarning = true
          } else {
            this.focusField('scanProduct')
            this.lockLocation = true
          }
        } else {
          const location = this.listOfLocations.find(x => x.name === this.scannedLocation)
          if (!location) {
            this.focusField('scanLocation')
            this.lockLocation = false
            this.scannedLocation = ''
            this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.product.noLocFound' })
          } else {
            this.lockLocation = true
          }
        }
        this.loadingLocation = false
      }
    },
    getRespectiveProductOrder () {
      if (this.scanProduct) {
        this.loadingProduct = true
        const list = this.$formatter.cloneVariable(this.sheetModel.stocks)
        if (list) {
          const getProduct = list.find(x => x.barcode === this.scanProduct || x.productnumber === this.scanProduct)
          if (getProduct) {
            const existingProduct = this.$formatter.cloneVariable(getProduct)
            existingProduct.id = existingProduct.productid
            existingProduct.batchrequired = existingProduct.producthasbatch
            this.productInfo = getProduct
            if (!getProduct.producthasbatch) {
              const found = list.find(x => x.productid === this.productInfo.productid && x.locationname === this.scannedLocation)
              if (found) this.selectedProduct = found
              else {
                this.showAddCount = false
                this.foundProduct = existingProduct
              }
            } else {
              this.showAddCount = false
              this.foundProduct = existingProduct
            }
            if (getProduct.producthasbatch) {
              this.focusField('batchRef')
            }
            this.productFound = true
          } else {
            this.productInfo = {}
            this.productFound = false
            this.showAddCount = true
            hostAppApi.get(`${this.getHostRefApi}get_product_by_search/${this.scanProduct}`).then(response => {
              if (response.data) {
                this.foundProduct = response.data
                // this.addProductConfirmDialog = true
                this.proceedWithProduct()
                // this.showAddCount = true
              } else {
                this.foundProduct = {}
                this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.product.noProductFound' })
                this.focusField('scanFocus')
                this.scanProduct = ''
                this.pickQty = ''
                this.selectBatch = ''
                this.selectedProduct = {}
              }
            })
          }
        }
        this.loadingProduct = false
      }
    },
    proceedWithProduct () {
      this.addProductConfirmDialog = false
      this.showAddCount = true
      if (this.foundProduct) {
        this.productInfo = { productname: this.foundProduct.name, productid: this.foundProduct.id, producthasbatch: this.foundProduct.batchrequired }
        this.productFound = true
        if (this.productInfo.producthasbatch) {
          setTimeout(() => {
            this.focusField('batchRef')
          })
        }
      }
    },
    addNewProduct (counted) {
      let found = null
      let foundLocationname = null
      if (this.sheetModel.stocks) {
        found = this.sheetModel.stocks.find(x => x.locationname === this.scannedLocation)
        if (!found) {
          found = this.listOfLocations.find(x => x.name === this.scannedLocation)
          if (found) {
            found.locationid = found.id
            foundLocationname = found.name
          }
        } else {
          foundLocationname = found.locationname
        }
      }
      if (found && found.locationid) {
        const model = {
          ccsheetid: this.sheetId,
          locationid: found.locationid,
          productid: this.foundProduct.id,
          productunit: this.foundProduct.productunit || '',
          batchnumber: '',
          onstock: 0,
          counted: counted,
          salesprice: this.foundProduct.costprice,
          locationname: foundLocationname
        }
        if (this.productInfo && this.productInfo.producthasbatch) {
          if (this.selectBatch) {
            model.batchnumber = this.selectBatch
            this.saveNewProductHandler(model)
          } else {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
          }
        } else {
          this.saveNewProductHandler(model)
        }
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.locationNotSet') })
      }
    },
    saveNewProductHandler (model) {
      hostAppApi.post(`${this.getHostRefApi}ccsheets/create_ccsheet_item?user_id=${this.userId}`, model).then(response => {
        if (response && response.data) {
          model.id = response.data.id
          if (this.foundProduct.name) {
            model.productname = this.foundProduct.name
            model.productnumber = this.foundProduct.number
            model.productunit = this.foundProduct.productunit
            model.producthasbatch = this.foundProduct.batchrequired
            if (this.foundProduct.unitid) {
              const productUnitObj = this.productUnits.find(x => x.id === this.foundProduct.unitid)
              if (productUnitObj) {
                model.productunit = productUnitObj.name
              }
            }
          } else if (this.foundProduct.productname) {
            model.productname = this.foundProduct.productname
            model.productnumber = this.foundProduct.productnumber
            model.productunit = this.foundProduct.productunit
            model.producthasbatch = this.foundProduct.producthasbatch
          }
          this.sheetModel.stocks.unshift(model)
          this.listOfProducts.unshift(model)

          // this.getCountedProducts()
          this.showAddCount = false
          this.scanProduct = ''
          this.pickQty = ''
          this.selectBatch = ''
          this.selectedProduct = {}
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('message.common.addedSucess') })
        }
      })
        .finally(() => {
          this.saveLoading = false
          this.countNextHandler()
          this.focusField('scanLocation')
          // this.getCountedProducts()
        })
    },
    getCountedProducts () {
      this.loading = true
      hostAppApi.get(`${this.getHostRefApi}ccsheets/get_ccsheet/${this.sheetId}?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { productunits, whlocations, ccsheet } = response.data
            this.productUnits = productunits
            this.listOfLocations = whlocations
            this.sheetModel = ccsheet
            this.loading = false
            /* this.getWarehouseLocations()
              .then(() => {
                this.loading = false
              }) */
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getListOfProducts () {
      this.loading = true
      const totalDisplay = 6
      const queryModel = {
        ccsheetid: this.sheetId,
        page: this.activePage,
        decending: false,
        sortby: 'id',
        rowsperpage: totalDisplay
      }
      hostAppApi.post(`${this.getHostRefApi}ccsheetitems/paginate_filter?user_id=${this.userId}`, queryModel)
        .then((response) => {
          const transformData = { number: { list: ['onstock', 'counted'], format: 'replaceDotWithCommaWOFixed' } }
          this.can_complete = response.data.can_complete
          const resultedItems = response.data.items || []
          this.listOfProducts = this.$formatter.formatListModel(resultedItems, transformData)
          this.totalLength = Math.ceil(response.data.total / totalDisplay) || 0
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getProductUnits () {
      this.loading = true
      this.$api.execute('get', 'productunits')
        .then((response) => {
          if (response.data) {
            this.productUnits = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    checkForValidBatchNumber () {
      this.loadingBatch = true
      const products = this.$formatter.cloneVariable(this.sheetModel.stocks)
      const found = products.find(x => x.productid === this.productInfo.productid && x.locationname === this.scannedLocation && x.batchnumber === this.selectBatch)
      if (found) {
        this.selectedProduct = found
        this.showAddCount = false
      } else {
        this.showAddCount = true
        if (this.foundProduct && this.foundProduct.batchrequired) {
          setTimeout(() => {
            this.focusField('qtyRef')
          })
        } else {
          this.selectBatch = ''
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.order.notValidBatch') })
          setTimeout(() => {
            this.focusField('batchRef')
          })
        }
      }
      this.loadingBatch = false
    },
    saveCountedProduct () {
      if (!this.$formatter.isNullOrEmpty(this.pickQty)) {
        const counted = +this.$formatter.replaceCommaWithDot(this.pickQty)
        if (this.showAddCount) {
          this.addNewProduct(counted)
        } else {
          if (this.selectedProduct.id) {
            this.saveLoading = true
            hostAppApi.post(`${this.getHostRefApi}ccsheets/save_counted_pwa/${this.selectedProduct.id}?user_id=${this.userId}&count=${counted || 0}&isContinueCouting=${this.isContinueCounting}`)
              .then(response => {
                if (response && response.data) {
                  const objIndex = this.sheetModel.stocks.findIndex(x => x.id === this.selectedProduct.id)
                  const obj = this.sheetModel.stocks.find(x => x.id === this.selectedProduct.id)
                  obj.counted = response.data.counted
                  this.$set(this.sheetModel.stocks, objIndex, obj)
                  const productObjIndex = this.listOfProducts.findIndex(x => x.id === this.selectedProduct.id)
                  const productObj = this.listOfProducts.find(x => x.id === this.selectedProduct.id)
                  if (productObj) {
                    productObj.counted = response.data.counted
                    this.$set(this.listOfProducts, productObjIndex, productObj)
                  }
                  this.showAddCount = false
                }
                this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
              })
              .finally(() => {
                this.saveLoading = false
                this.countNextHandler()
                this.focusField('scanLocation')
                // this.getCountedProducts()
              })
          } else {
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.ccsheet.noProductOnLocation') })
            this.selectedProduct = {}
          }
        }
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    completeCountingHandler () {
      this.completeDialog = true
    },
    completeSheetItems () {
      this.completeLoading = true
      const model = { sheetid: this.sheetId, stocks: [] }
      const transformData = { number: { list: ['onstock', 'counted'], format: 'replaceCommaWithDot' } }
      model.stocks = this.$formatter.formatListModel(this.sheetModel.stocks, transformData)
      hostAppApi.post(`${this.getHostRefApi}ccsheets/complete_ccsheet?user_id=${this.userId}`, model)
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
          this.$router.push('/cycle_count')
        })
        .finally(() => {
          this.completeDialog = false
          this.completeLoading = false
        })
    },
    scanNextLocation () {
      this.scannedLocation = ''
      this.lockLocation = false
      this.focusField('scanLocation')
    },
    countNextHandler () {
      this.selectBatch = ''
      this.selectedProduct = {}
      this.pickQty = 0
      this.scanProduct = ''
      this.productFound = false
      this.productInfo = {}
      this.foundProduct = {}
    },
    continueCoutingHandler () {
      this.isContinueCounting = 1
      this.isClearAndCount = 0
      this.showWarning = false
      this.lockLocation = true
    },
    clearAndRecountHandler () {
      this.isContinueCounting = 0
      this.isClearAndCount = 1
      this.showWarning = false
      this.lockLocation = true
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          this.$refs[value].focus()
        }
      })
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    clearPickedQty () {
      this.pickQty = ''
    },
    getWarehouseLocations () {
      const model = {
        filter: `isactive eq 1 AND warehouseid eq ${this.sheetModel.warehouseid}`,
        order_by: {
          field: 'name',
          asc_or_desc: 'asc'
        }
      }
      return this.$api.execute('post', 'warehouselocations/query', model)
        .then((response) => {
          this.listOfLocations = response.data
        })
    }
  }
}
</script>
<style>
.complete_count_btn {
  bottom: 4rem!important;
}
</style>
